export default function Icond3() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M9.25051 12.1526L20.5 7.5802L22.3 12.1526M12.8495 16.4432H15.5505M12.8495 19.1864H19.1505M7 12.1526H25V14.8959C23.6505 14.8959 22.3 15.81 22.3 17.4101C22.3 19.0103 23.6505 20.3824 25 20.3824V23.1256H7V20.3824C8.35051 20.3824 9.7 19.4683 9.7 17.6391C9.7 15.81 8.34949 14.8959 7 14.8959V12.1526Z"
                stroke="#010101"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
        </svg>
    );
}
